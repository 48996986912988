<template>
  <div class="party">
    <el-form 
    :model="ruleForm" 
    ref="ruleForm" 
    class="demo-ruleForm">
      <div class="partyCon"
      v-for='(formItem,idx) in ruleForm.formList'
      :key='formItem.id'>
        <div class="partyUl">
          <div class="partyLi">
            <div class="itemTop">
              <div class="typeSelect">
                <el-form-item style='margin-bottom:0'>
                  <el-select v-model="formItem.type" size='small' style='width:100px;'>
                    <el-option
                      v-for="item in lawyerTypeRes"
                      :key='item.key'
                      :label="item.value"
                      :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="typeSelect">
                <el-form-item style='margin-bottom:0'
                :prop="'formList.'+idx+'.nameinput'"
                :rules="{required: true, message: '请输入律师姓名', trigger: ['blur','change']}">
                  <el-autocomplete  :trigger-on-focus='false' size='small' style='width:315px;' 
                  @select="blurCheck($event,idx)"
                    v-model="formItem.nameinput"
                    :fetch-suggestions="querySearchAsync"
                    placeholder="请输入律师姓名"
                  ></el-autocomplete>
                </el-form-item>
              </div>
              <div class="iconBox">
                <div class="el-icon-remove-outline" 
                v-if='idx'
                @click='deletItem(idx)'></div>
                <div class="el-icon-circle-plus-outline" 
                v-if='idx===0'
                @click='add'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { defineComponent,onMounted,ref } from 'vue';
import {useStore} from 'vuex';
import {lawyerSelect} from '@/api';

const lawyerType = (store)=>{
  let lawyerTypeRes = ref([]);
  const getLawyerType = async ()=>{
    return await store.dispatch('getDataDictionary',10008);
  }
  onMounted(async ()=>{
    lawyerTypeRes.value = await getLawyerType()
  })
  return {
    lawyerTypeRes
  }
};
export default defineComponent({
  name:"lawyerParty",
  setup() {
    const store = useStore();
    const {lawyerTypeRes} = lawyerType(store);
    return {
      lawyerTypeRes
    }
  },
  props:{
    lawyerArr:{
      type:Array,
      default:function(){
        return 
      }
    }
  },
  created(){
    if(this.lawyerArr&&this.lawyerArr.length){
      this.ruleForm.formList.splice(0,1);
      this.lawyerArr.forEach(item=>{
        this.ruleForm.formList.push({
          id:item.selfKey,
          type:item.lawyerType,
          nameinput:item.lawyerName,
          keyL:item.lawyerUserId
        })
      });
    }
  },
  data(){
    return {
      ruleForm: {
        formList:[
          {
            id:1,
            type:1,
            nameinput:'',
            keyL:''
          }
        ]
      },
      serchData:[]
    }
  },
  methods:{
    // 校验
    checkIsRequire(cb){
      this.$refs['ruleForm'].validate((val)=>{
        cb(val);
      });
    },
    // 重置
    resetFields(){
      this.$refs['ruleForm'].resetFields();
    },
    blurCheck(val,idx){
      this.ruleForm.formList[idx].keyL = val.keyL;
    },
    async querySearchAsync(queryString, cb){
      if(queryString){
        let res = await lawyerSelect({
          lawyerName:queryString
        });
        if(res&&res.code==200){
          this.serchData = res.data;
          cb(res.data);
        }      
      };
    },
    add(){
      this.ruleForm.formList.push({
        id:Date.now(),
        type:1,
        nameinput:'',
        keyL:''
      })
    },
    deletItem(idx){
      this.ruleForm.formList.splice(idx,1)
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.party{
  .title{
    display: flex;
    align-items: center;
    margin-bottom:20px;
    .text{
      font-size: 14px;
      color:#666;
    }
  }
  .partyCon{
    margin-bottom:10px;
    .partyUl{
      .partyLi{
        
        .itemTop{
          display: flex;
          align-items: center;
          .typeSelect{
            &:first-child{
              margin-right: 4px;
            }
          }
          .iconBox{
            display: flex;
            align-items: center;
            .el-icon-circle-plus-outline,.el-icon-remove-outline{
              margin-left:10px;
              font-size: 20px;
              color:#999;
              &:hover{
                cursor: pointer;
                color:$main-color;
              }
            }
          }
          
        }
        .itemName{
          margin:10px 0;
          width:464px;
        }
        .itembottom{
          width:464px;
          font-size: 12px;
          color:#666;
          display: flex;
          align-items: center;
          justify-content: space-between;
          ::v-deep{
            .el-checkbox__label{
              font-size: 12px;
              color:#666;
            }
          }
          .status{
            display: flex;
            align-items: center;
            font-size: 12px;
            color:#666;
            .icon{
              font-size:18px;
              margin-right: 10px;
            }
          }
          .none{
            color:#62BE8A
          }
          .exist{
            color:#E8431C
          }
          
        }
        .fleend{
          display: flex;
          align-items: center;
          justify-content: flex-end
        }
      }
    }
  }
}
</style>
