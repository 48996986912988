<template>
  <div class="CaseMainDetails">
    <div class="leftBox">
      <!-- 基本信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">基本信息</div>
          <div class="iconBox el-icon-edit-outline" @click='openOppIsShow("base")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">项目编号</div>
            <div class="valueTitle">苏永民字(2021)第101号</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">委托时间</div>
            <div class="valueTitle">2021-01-12 12:23:36</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">委托方</div>
            <div class="valueTitle">南京某某某科技有限公司</div>
          </div>
        </div>
      </div>
      <!-- 经办律师 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">经办律师</div>
          <div class="iconBox el-icon-edit-outline" @click='openOppIsShow("lawyer")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyIcon iconfont icontouxiang"></div>
            <div class="keyTitle">张三</div>
            <div class="valueTitle">主办</div>
          </div>
          <div class="contentItem">
            <div class="keyIcon iconfont icontouxiang"></div>
            <div class="keyTitle">李四</div>
            <div class="valueTitle">协办</div>
          </div>
          <div class="contentItem">
            <div class="keyIcon iconfont icontouxiang"></div>
            <div class="keyTitle">王五</div>
            <div class="valueTitle">律师助理</div>
          </div>
        </div>
      </div>
      <!-- 收费信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">收费信息</div>
          <div class="iconBox el-icon-edit-outline" @click='openOppIsShow("charge")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">合同预估</div>
            <div class="valueTitle">23000元</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle textRight">备注</div>
            <div class="valueTitle flex1">前期金额总计1000元，已打入相关账号；案件结束后，剩余金额2000元另付；同时追回的债款中的1%作为风险金额；</div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightBox">
      <!-- 联系人信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">联系人信息</div>
          <div class="iconBox el-icon-edit-outline" @click='openOppIsShow("contactInfo")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyIcon iconfont icontouxiang"></div>
            <div class="keyTitle">张三</div>
            <div class="valueTitle">业务部经理</div>
            <div class="valueTitle">15261401518</div>
          </div>
          <div class="contentItem">
            <div class="keyIcon iconfont icontouxiang"></div>
            <div class="keyTitle">李四</div>
            <div class="valueTitle">门卫</div>
            <div class="valueTitle">15261401518</div>
          </div>
        </div>
      </div>
      <!-- 关联案件 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">关联案件</div>
          <div class="iconBox el-icon-edit-outline" @click='openOppIsShow("relationCaseInfo")'></div>
        </div>
        <div class="contentBox">
          <div class="contentUl">
            <div class="contentLi">
              <div class="liTop">
                <div>2020-05-12</div>
                <div>苏永（民）2020 第 101 号</div>
              </div>
              <div class="lititle">王某某和张三婚姻财产分配纠纷案王某某和张三婚姻财产分配纠纷案</div>
            </div>
            <div class="contentStatus">进行中</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 基本信息 -->
    <myDialog
    parentRef='base'
    @dialogAfterLeave='afterLeave("base")'
    title='编辑基本信息'
    :isShow='baseOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo">
        <div class="formBox">
          <el-form
          :model="baseForm" 
          label-width="120px">
            <el-form-item label="项目类型"
            prop='projectType'
            :rules="{
              required: true,trigger: 'change'
            }">
              <el-select size='small' style='width:350px'
              v-model="baseForm.projectType" placeholder="请选择">
                <el-option
                  v-for="item in projectType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目名称"
            prop='projectName'
            :rules="{
              required: true, message: '项目名称不能为空', trigger: 'blur'
            }">
              <el-input style='width:350px' size='small' v-model="baseForm.projectName" clearable></el-input>
            </el-form-item>
            <el-form-item label="委托方">
              <div class="inlineBox">
                <el-select size='small'  style='width:100px'
                  v-model="baseForm.client" placeholder="请选择">
                  <el-option
                    v-for="item in clientType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <div class='columnBox' style='width:230px'>
                  <el-autocomplete size='small'
                    v-model="baseForm.custom"
                    :fetch-suggestions="querySearchAsync"
                    placeholder="请输入内容"
                    @select="handleSelect"
                  ></el-autocomplete>
                  <div class='checkBox'>
                    <el-checkbox v-model="baseForm.isCreateCustom">同步新建客户</el-checkbox>
                  </div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="委托时间">
              <el-date-picker size='small' style='width:350px'
                v-model="baseForm.date"
                type="datetime"
                placeholder="选择委托时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="开始时间">
              <el-date-picker size='small' style='width:350px'
                v-model="baseForm.creatDate"
                type="datetime"
                placeholder="选择开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间">
              <el-date-picker size='small' style='width:350px'
                v-model="baseForm.overDate"
                type="datetime"
                placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="btnFooter">
            <el-button size='small' type="primary" plain>取消</el-button>
            <el-button size='small' type="primary" style='margin-left:20px'>保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 经办律师 -->
    <myDialog
    parentRef='lawyer'
    @dialogAfterLeave='afterLeave("lawyer")'
    title='编辑经办律师'
    :isShow='lawyerOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo" style='padding-left:75px'>
        <div class="formBox">
          <div class='lawyerFormBox'>
            <lawyerParty></lawyerParty>
          </div>
          <div class="textareaBox">
            <div class='text'>备注</div>
            <el-input size='small' type='textarea' style='width:450px;'
              v-model="beizhu" clearable></el-input>
          </div>
          <div class="btnFooter" style='width:450px;'>
            <el-button size='small' type="primary" plain>取消</el-button>
            <el-button size='small' type="primary" style='margin-left:20px'>保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 收费信息 -->
    <myDialog
    parentRef='charge'
    @dialogAfterLeave='afterLeave("charge")'
    title='编辑收费信息'
    :isShow='chargeOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo">
        <div class="formBox">
          <el-form 
          :model="chargFormData" 
          ref="chargformBox" 
          label-width="110px">
          <el-form-item label="合同金额">
            <el-input placeholder="请输入合同金额" size='small' style='width:375px;'
            v-model="chargFormData.account">
              <template #append>元</template>
            </el-input>
          </el-form-item>
            <el-form-item label="收款备注">
              <div class="textareaBox" style='margin-top:0;width:375px;'>
                <el-input size='small' type='textarea' 
                v-model="chargFormData.beizhu" clearable></el-input>
              </div>
            </el-form-item>
            <div class="btnFooter" style='width:450px;'>
              <el-button size='small' type="primary" plain>取消</el-button>
              <el-button size='small' type="primary" style='margin-left:20px'>保存</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </myDialog>
    <!-- 联系人信息 -->
    <myDialog
    parentRef='contactInfo'
    @dialogAfterLeave='afterLeave("contactInfo")'
    title='编辑联系人信息'
    :isShow='contactInfoOppIsShow'
    :closeDialog='closeDialog'>
      <div :class='["baseInfo",contactFormBox.contactList.length>1?"contactInfo":""]'>
        <el-form
        :model="contactFormBox" 
        ref="contactFormBox" 
        label-width="80px"
        >
          <div class="contactItem" 
          v-for='(item,idx) in contactFormBox.contactList'
          :key='item.id'>
            <div class="inlineBox">
              <el-form-item label="姓名" class="itemInlineBox" 
              :prop="'contactList.'+idx+'.name'"
              :rules="{
                required: true,trigger: 'blur',message: '请输入姓名'
              }">
                <el-input style='width:230px' size='small' 
                v-model="item.name"></el-input>
                <el-select size='small'  style='width:150px;margin-top:1px'
                  v-model="item.gender">
                  <el-option
                    v-for="item in genderSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="inlineBox">
              <el-form-item label="职务">
                <el-input size='small' v-model="item.job" style='width:150px'></el-input>
              </el-form-item>
              <el-form-item label="联系方式">
                <el-input size='small' v-model="item.tel" style='width:150px'></el-input>
              </el-form-item>
            </div>
            <el-form-item label="备注">
              <el-input size='small' type='textarea' v-model="item.beizhu" style='width:380px'></el-input>
            </el-form-item>
            <div class="el-icon-circle-plus-outline addBtn" @click='addContact' v-if='idx===0'></div>
            <div class="el-icon-remove-outline addBtn" @click='deletContact(idx)' v-else></div>
          </div>
        </el-form>
        <div class="btnFooter">
          <el-button size='small' type="primary" plain>取消</el-button>
          <el-button size='small' type="primary" style='margin-left:20px' @click='submitContactForm'>保存</el-button>
        </div>
      </div>
    </myDialog>
    <!-- 关联案件 -->
    <myDialog
    parentRef='relationCaseInfo'
    @dialogAfterLeave='afterLeave("relationCaseInfo")'
    title='编辑联系人信息'
    :isShow='relationCaseInfoOppIsShow'
    :closeDialog='closeDialog'
    >
      <!-- 关联的案件列表 -->
      <div class='relationCaseList' v-show='isEditor'>
        <div class="listBox">
          <div class="contentUl">
          <div class="contentLi">
            <div class="liTop">
              <div>2020-05-12</div>
              <div>苏永（民）2020 第 101 号</div>
            </div>
            <div class="lititle">王某某和张三婚姻财产分配纠纷案王某某和张三婚姻财产分配纠纷案</div>
          </div>
          <div class="contentStatus">进行中</div>
          </div>
        </div>
        <div class="bottomBtn">
          <el-button type='primary' size='small' style='width:80px'
          @click='isEditor = false'>编辑</el-button>
        </div>
      </div>
      <!-- 可以添加关联的案件列表 -->
      <div class="addRelationCaseLis" v-show='!isEditor'>
        <div class="topSerch">
          <el-input class='topserchInput'
            placeholder="请输入案件名称/案件编号/当事人 按回车键搜索"
            prefix-icon="el-icon-search"
            v-model="addRelationCaseSerch">
          </el-input>
        </div>
        <div class="listBox">
          <div class="contentUl" v-for='(item,idx) in addRelationCaseSerchList' :key='idx'>
            <div class="contentIc"
            @click='currentCheck(idx)'><span v-show='item.isSelect' class="el-icon-check"></span></div>
            <div class="contentLi">
              <div class="liTop">
                <div>{{item.date}}</div>
                <div>{{item.order}}</div>
              </div>
              <div class="lititle">{{item.name}}</div>
            </div>
            <div class="contentStatus">{{item.status}}</div>
          </div>
        </div>
        <div class="bottomBtn">
          <el-button type='primary' plain size='small' style='width:80px'
          @click='isEditor = true'>取消</el-button>
          <el-button type='primary' size='small' style='width:80px'
          @click='saveContactForm'>保存</el-button>
        </div>
      </div>
    </myDialog>
    
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import myDialog from '@/components/myDialog.vue';
import lawyerParty from '@/components/lawyerParty.vue';
import MyDialog from '../components/myDialog.vue';
export default defineComponent({
  name:"ProjectMainDetails",
  components:{
    myDialog,
    lawyerParty,
    MyDialog
  },
  data(){
    return {
      isEditor:true,
      baseOppIsShow:false,
      lawyerOppIsShow:false,
      chargeOppIsShow:false,
      contactInfoOppIsShow:false,
      relationCaseInfoOppIsShow:false,
      baseForm:{
        projectType:'',
        projectName:'',
        client:'',
        custom:'',
        isCreateCustom:false,
      },
      beizhu:'',
      chargFormData:{
        account:'',
        beizhu:''
      },
      projectType:[
        {
          value:1,
          label:'非诉专项'
        }
      ],
      clientType:[
        {
          value:1,
          label:'自然人'
        }
      ],
      genderSelect:[
        {
          value:1,
          label:'先生'
        }
      ],
      contactFormBox:{
        contactList:[
          {
            id:1,
            gender:1,
            name:'',
            job:'',
            tel:'',
            beizhu:''
          }
        ]
      },
     addRelationCaseSerch:'',
     addRelationCaseSerchList:[
      {
         status:'进行中',
         name:'王某某和张三婚姻财产分配纠纷案',
         date:'2020-05-12',
         order:'苏永（民）2020 第 101 号',
         isSelect:false
      }
     ]
    }
  },
  methods:{
    openOppIsShow(typeOpp){
      console.log('openOppIsShow:',typeOpp)
      switch(typeOpp){
        case 'base':this.baseOppIsShow = true;break;
        case 'lawyer':this.lawyerOppIsShow = true;break;
        case 'charge':this.chargeOppIsShow = true;break;
        case 'contactInfo':this.contactInfoOppIsShow = true;break;
        case 'relationCaseInfo':this.relationCaseInfoOppIsShow = true;break;
        
      }
    },
    afterLeave(typeOpp){
      console.log('afterLeave:',typeOpp);
      switch(typeOpp){
        case 'base':this.baseOppIsShow = false;break;
        case 'lawyer':this.lawyerOppIsShow = false;break;
        case 'charge':this.chargeOppIsShow = false;break;
        case 'contactInfo':this.contactInfoOppIsShow = false;break;
        case 'relationCaseInfo':this.relationCaseInfoOppIsShow = false;break;
      }
    },
    closeDialog(v){
      console.log('closeDialog:',v)
      if(v){
        switch(v){
          case 'base':this.baseOppIsShow = false;break;
          case 'lawyer':this.lawyerOppIsShow = false;break;
          case 'charge':this.chargeOppIsShow = false;break;
          case 'contactInfo':this.contactInfoOppIsShow = false;break;
          case 'relationCaseInfo':this.relationCaseInfoOppIsShow = false;break;
        }
      }
    },
    handleSelect(v){
      console.log(v)
    },
    querySearchAsync(str,cb){},
    addContact(){
      this.contactFormBox.contactList.push({
        id:Date.now(),
        gender:1,
        name:'',
        job:'',
        tel:'',
        beizhu:''
      })
    },
    deletContact(idx){
      this.contactFormBox.contactList.splice(idx,1)
    },
    submitContactForm(){
      console.log(this.contactFormBox)
    },
    currentCheck(idx){
      this.addRelationCaseSerchList[idx].isSelect = !this.addRelationCaseSerchList[idx].isSelect
    },
    saveContactForm(){}
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.CaseMainDetails{
  display: flex;
  width:100%;
  box-sizing: border-box;
  padding:0 30px;
  .leftBox{
    border-right:1px solid #eee;
  }
  .leftBox,.rightBox{
    width:50%;
    box-sizing: border-box;
    .itemBox{
      border-bottom:1px solid #eee;
      padding:30px 0;
      margin:0 20px;
      &:last-child{
        border:none;
      }
      .titleBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom:30px;
        .title{
          font-size: 16px;
          color:#666666;
        }
        .iconBox{
          font-size: 26px;
          color:$main-color;
          &:hover{
            cursor: pointer;
          }
        }
      }
      .contentBox{
        padding-left:10px;
        .contentItem{
          margin-bottom:30px;
          display: flex;
          align-items: center;
          .keyTitle{
            min-width:56px;
            margin-right: 20px;
          }
          .textRight{
            text-align: right;
          }
          .flex1{
            flex:1;
            line-height: 20px;
          }
          .keyTitle,.valueTitle{
            font-size: 14px;
            color:#777777;
          }
          .valueTitle{
            min-width: 80px;
            margin-right: 20px;
          }
          .keyIcon{
            font-size: 40px;
            color:$main-color;
            margin-right: 30px;
          }
        }
        .contentUl{
          padding:20px;
          width:100%;
          border-radius: 10px;
          box-sizing:border-box;
          box-shadow: 2px 2px 5px rgb(220 223 230);
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom:20px;
          .contentLi{
            width:260px;
            .liTop{
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 12px;
              color:#666;
              margin-bottom:20px;
            }
            .lititle{
              font-size: 14px;
              color:#666;
              width:100%;
              @include ellipsis;
            }
          }
          .contentStatus{
            font-size: 14px;
            color:#666;
          }
        }
      }
    }
  }
  .rightBox{
    padding-left:20px;
    .contentBox{
      .tableBoxCon{
        width:90%;
        display: flex;
        margin:auto;
        margin-bottom:20px;
        .tableTitle{
          font-size: 16px;
          color:#666;
          width:60px;
        }
        .tableBox{
          width:100%;
          .tableItem{
            display: flex;
            width:100%;
            margin-bottom:20px;
            &:last-child{
              margin-bottom:0;
            }
            .tableItemName{
              // width:calc( (100% - 60px) / 2 );
              width:165px;
              font-size: 14px;
              color:#777;
              &:nth-child(2){
                width:100px;
              }
              &:nth-child(3){
                width:150px;
              }
            }
          }
        }
      }
    }
  }
}
.baseInfo{
  padding:30px;
  .btnFooter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin:30px auto 0 auto;
  }
  .textareaBox{
    margin-top:30px;
    .text{
      font-size: 14px;
      color:#666;
      margin-bottom:20px;
    }
  }
  .inlineBox{
    display: flex;
    align-items: top;
  }
  .columnBox{
    margin-left: 20px;
    line-height: 32px;
    display: flex;
    flex-direction: column;
    .checkBox{
      display: flex;
      justify-content: flex-end;
    }
  }
  .itemInlineBox{
    ::v-deep{
      .el-form-item__content{
        display: flex;
      }
    }
  }
  .contactItem{
    position: relative;
    padding:30px 0 10px 0;
    box-shadow: 2px 2px 5px rgb(220 223 230);
    border-radius:10px;
    margin-bottom:30px;
    .addBtn{
      position: absolute;
      right:20px;
      top:30px;
      font-size: 28px;
      color:#999;
      &:hover{
        cursor: pointer;
      }
    }
  }
}
.contactInfo{
  overflow-y: scroll;
  height:600px
}
.relationCaseList,.addRelationCaseLis{
  .listBox{
    height:400px;
    overflow-y: scroll;
    padding:30px;
    .contentUl{
      padding:20px;
      width:100%;
      border-radius: 10px;
      box-sizing:border-box;
      box-shadow: 2px 2px 5px rgb(220 223 230);
      display: flex;
      align-items: center;
      margin-bottom:20px;
      .contentIc{
        width:22px;
        height:22px;
        border-radius: 100%;
        border:2px solid $main-color;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color:$main-color;
        font-weight: 800;
        &:hover{
          cursor:pointer
        }
      }
      .contentLi{
        width:260px;
        margin:0 100px 0 35px;
        .liTop{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color:#666;
          margin-bottom:20px;
        }
        .lititle{
          font-size: 14px;
          color:#666;
          width:100%;
          @include ellipsis;
        }
      }
      .contentStatus{
        font-size: 14px;
        color:#666;
      }
    }
  }
  .bottomBtn{
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.addRelationCaseLis{
  padding:30px;
  min-height:400px;
  .listBox{
    padding:10px;
    margin-top:20px;
  }
  .topSerch{
    .topserchInput{
      border-radius:40px;
      ::v-deep{
        .el-input__inner{
          border-radius:40px;
        }
      }
    }
  }
}
</style>